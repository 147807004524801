export const widgetOpts = {
        siteInfo: {
                topFullWidth: ['site-information', 'site-notes', 'site-rma'],
                left: ['battview-list'],
                right: ['charger-list'],
                bottomFullWidth: []
        },
        company: {
                topFullWidth: [],
                left: ['customer-estimated-counts', 'overall-battery-replacement-report' ],
                right: ['map-view', 'all-sites-battery-fleet-utilization' ],
                bottomFullWidth: []
        },
        'battery-performance': {
                topFullWidth: [],
                left: ['battery-fleet-utilization', 'eq-weekly-summary'],
                right: ['ebu-daily-usage-summary', 'site-idle-times', 'plugged-in-missed-connection'],
                bottomFullWidth: ['battery-alert-summary', 'hourly-idle-times', 'battery-plc-activity'],
        },
        'battery-performance-new': {
                topFullWidth: ['hourly-idle-times-new', 'site-idle-times-new'],
                left: ['battery-fleet-utilization-new', 'eq-weekly-summary-new', 'ebu-daily-usage-summary-new', 'battery-plc-activity-new'],
                right: ['battery-alert-summary-new', 'site-battery-aging-new'],
                bottomFullWidth: [ ],
        },
        'battery-alerts': {
                topFullWidth: [],
                left: ['missed-eqs-weekly', 'over-under-charge', 'ebu-over-usage-alert', 'missed-fi', 'deep-discharge-alerts', 'sulfated-battery-alerts', 'missed-plugin-charge-cycles', 'cold-temperature-alerts'],
                right: ['high-temperature-alerts', 'deep-use-alerts', 'water-level-alerts', 'missed-daily-connection', 'missed-connection-plugged-in-alert', 'idle-times-alert', 'missed-eqs'],
                bottomFullWidth: []
        },
        'battery-aging': {
                topFullWidth: [],
                left: ['site-battery-aging'],
                right: ['battery-remaining-warranty'],
                bottomFullWidth: []
        },
        'chargers-performance': {
                topFullWidth: ['charger-assets-utilization', 'charger-peak-whr', 'kwhrs-output', 'site-energy-consumption', 'site-alerts', 'plc-activity', 'ahrs-returned', 'charger-plugins'],
                left: [],
                right: [],
                bottomFullWidth: []
        },
        'chargers-performance-new': {
                topFullWidth: ['site-energy-consumption-new', 'kwhrs-output-new'],
                left: ['charger-assets-utilization-new', 'ahrs-returned-new', 'charger-peak-whr-new', 'plc-activity-new' ],
                right: ['site-alerts-new', 'charger-plugins-new'],
                bottomFullWidth: []
        },
        'battery-reports': {
                topFullWidth: ['site-batteries', 'site-ebus', 'site-temperature'],
                left: [],
                right: [],
                bottomFullWidth: []
        },
        'truck-reports': {
                topFullWidth: ['site-trucks', 'site-truck-ebus'],
                left: [],
                right: [],
                bottomFullWidth: []
        },
        'truck-performance': {
                topFullWidth: ['truck-fleet-utilization', 'truck-ebu-daily-usage-summary', 'truck-site-idle-times'],
                left: [],
                right: [],
                bottomFullWidth: [],
        },
        default: {
                topFullWidth: [],
                left: [],
                right: [],
                bottomFullWidth: []
        }
};
